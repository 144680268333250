import { useEffect, useState } from 'react';

import request from 'axios';

const useVerification = (code) => {

  if (code === undefined || code === null) code = '';

  const [status, setStatus] = useState('');

  useEffect(() => {
    if (code.length === 0) setStatus('');
  }, [code]);

  const verify = () => {
    if (code.length === 0) return;
    setStatus('verifying');
    return new Promise((resolve, reject) => {
      request
      .post('https://us-central1-mlw-firebase.cloudfunctions.net/verifyCode', { code })
      .then((response) => {
        setStatus('valid');
        resolve(response);
      })
      .catch((error) => {
        setStatus('invalid');
        reject(error);
      });
    });
  };

  return [status, verify];

};

export default useVerification;
