import React, { useState } from 'react';

import request from 'axios';

import * as Yup from 'yup';
import IMask from 'imask';

import { FormContainer } from '../../lib';

import { validate as validateCPF } from 'gerador-validador-cpf';

const mask = IMask.createMask({ mask: '000.000.000-00' });

const validationSchema = Yup.object().shape({
  document: Yup.string().matches(/(^\d{3}\.\d{3}\.\d{3}\-\d{2}$)/, 'Invalid document').required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
});

const Form = ({ code, codeIsValid, institutionId, onChangeStatus }) => {

  const [isRealCPF, setCPFValidity] = useState(null);

  return (
    <div className={`column is-half-desktop form-mobile-behavior ${code ? 'is-active' : ''}`}>

      <div className="title is-size-3">Dados Pessoais</div>

      <div className="v-space" />

      <p className="text">
        Estamos pedindo algumas informações suas para completar a seleção da ONG
        <br/>
        Cada CPF pode cadastrar até duas peças para doação, conforme regulamento da campanha.
      </p>

      <div className="v-space-xlg" />

      <FormContainer
        initialValues={{
          name: '',
          email: '',
          document: ''
        }}
        validationSchema={validationSchema}
        onSubmit={({ name, email, document }, { setSubmitting }) => {
          onChangeStatus({ status: 'loading' });
          request
          .post('https://us-central1-mlw-firebase.cloudfunctions.net/submitCode', {
            code,
            institutionId,
            name,
            email,
            document
          })
          .then((response) => {
            onChangeStatus({ status: 'success' });
            setSubmitting(false);
          })
          .catch((error) => {
            let code = '';
            try {
              code = error.response.data.code;
            } catch (err) {
              console.log(err);
            }
            onChangeStatus({ status: 'error', code });
            setSubmitting(false);
          });
        }}
        render={({ values, handleChange, setFieldError, handleSubmit, isSubmitting, isValid }) => (
          <>
            <div className="field">
              <label htmlFor="name" className="label">Nome completo</label>
              <div className="control">
                <input
                  id="name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  className="input"
                  type="text"
                />
              </div>
            </div>

            <div className="field">
              <label htmlFor="email" className="label">E-mail</label>
              <div className="control">
                <input
                  id="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  className="input"
                  type="text"
                />
              </div>
            </div>

            <div className="field">
              <label htmlFor="document" className="label">CPF</label>
              <div className="control">
                <input
                  id="document"
                  name="document"
                  value={values.document}
                  onChange={(event) => {
                    event.target.value = mask.resolve(event.target.value);
                    const cpfIsValid = validateCPF(event.target.value);
                    setCPFValidity(cpfIsValid);
                    if (!cpfIsValid) setFieldError('document', 'CPF inválido.');
                    handleChange(event);
                  }}
                  className={'input' + ((!isRealCPF && values.document.length > 0) ? ' is-danger' : '')}
                  type="text"
                />
              </div>
              <p className={'help' + ((!isRealCPF && values.document.length > 0) ? ' is-danger' : '')}>Digite um CPF válido</p>
            </div>

            <div className="v-space" />

            <button
              type="submit"
              onClick={handleSubmit}
              className="button is-small"
              disabled={!isRealCPF || !codeIsValid || isSubmitting}>
              <span>Fazer doação</span>
            </button>
          </>
        )}
      />
    </div>
  );
};

export default Form;
