import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import useModal from '../../hooks/useModal';
import useDebounce from '../../hooks/useDebounce';

import useVerification from '../../hooks/useVerification';

import Reader from './Reader';

import IMask from 'imask';

const mask = IMask.createMask({ mask: /^[0-9]\d{0,13}$/ });

const Barcode = ({ onChange, timestamp }) => {

  const inputRef = useRef();
  const [Modal, toggleModal] = useModal();
  const [code, setCode] = useState('');
  const [status, verify] = useVerification(code);
  const isReadyToVerify = useDebounce(code, 600);

  useEffect(() => {
    setCode('');
  }, [timestamp]);

  useEffect(() => {
    if (isReadyToVerify) verify();
  }, [isReadyToVerify]);

  useEffect(() => {
    if (inputRef && inputRef.current) inputRef.current.focus();
  }, [inputRef.current]);

  useEffect(() => {
    if (status !== 'verifying') onChange(code, status === 'valid');
  }, [status]);

  let statusClassName = '';

  const handleCode = (code) => {
    setCode(code);
    if (code.length < 4) statusClassName = 'is-invalid';
    if (code.length === 0) statusClassName = '';
  };

  if (status === 'verifying') statusClassName = 'is-verifying';
  if (status === 'valid') statusClassName = 'is-valid';
  if (status === 'invalid') statusClassName = 'is-invalid';

  return (
    <div className="column is-half-desktop">
      <div className="code-reader">
        <header className="code-reader-header">
          <div className="visible-desktop-false">
            <h1 className="title is-large">Cadastro do código</h1>
            <div className="v-space" />
            <p className="text">
              Procure na etiqueta do produto da campanha (válido para peças das coleções Malwee (Alto Verão, Celebration, Malwee Kids e Malwee Liberta)
              <br />
              <span className="is-highlighted">o código com 14 digitos que começa com <strong>"10000"</strong> (1 seguido de 4 zeros) da <strong>#atitudedoBem.</strong></span>
            </p>
            <div className="v-space" />
          </div>
          <p className="text">Siga o modelo da etiqueta abaixo</p>
          <div className="v-space" />
        </header>
        <div
          className={`code-reader-component ${statusClassName}`}
          style={{ backgroundImage: `url(${require('../../../images/backgrounds/code-reader.svg')})` }}>
          
          <div className="code-reader-input">
            <input
              ref={inputRef}
              type="text"
              className="code-input"
              value={code}
              style={{ textAlign: 'center' }}
              onChange={({ target }) => {
                target.value = mask.resolve(target.value);
                handleCode(target.value)
              }}
            />
          </div>

          {status === 'verifying' && (
            <span
              className="valid-feedback"
              style={{ color: '#FDB216', opacity: 1, transform: 'scale(1)' }}>
              Verificando código...
            </span>
          )}
          <span className="valid-feedback">
            Código validado com sucesso
          </span>
          <span className="invalid-feedback">
            Código inválido. Verifique se o código de barras é o correto
          </span>

        </div>

        <div className="code-reader-mobile-control visible-desktop-false">
          <p className="text">Ou se preferir ...</p>
          <div className="v-space" />
          <button onClick={toggleModal} className="button is-small"><span>Escaneie o código</span></button>
          <div className="v-space" />
        </div>
        <p className="text">
          *Algumas etiquetas não possuem a hashtag <span className="is-500">#atitudesdobem</span>, mas não tem problema. Basta digitar o código com 14 dígitos que fica logo abaixo do código de barras.
        </p>
      </div>

      <Modal isCover>
        <Reader
          onFinish={(code) => {
            setTimeout(() => handleCode(code || ''), 200);
            toggleModal(false);
          }}
        />
      </Modal>

    </div>
  );
};

Barcode.defaultProps = {
  onChange: () => {}
};

Barcode.propTypes = {
  onChange: PropTypes.func,
  timestamp: PropTypes.any
};

export default Barcode;
