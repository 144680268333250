import React, { useState } from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

import Barcode from '../components/partials/submit/Barcode';
import Form from '../components/partials/submit/Form';

import useModal from '../components/hooks/useModal';

import useSearchParam from '../components/hooks/useSearchParam';
import Feedbacks from '../components/partials/submit/Feedbacks';

const Submit = (props) => {

  const institutionId = useSearchParam('uid');
  
  const [code, setCode] = useState();
  const [codeStatus, setCodeStatus] = useState();
  
  const [formStatus, setFormStatus] = useState({});
  const [timestamp, setTimestamp] = useState(new Date());
  const [Modal, toggleModal] = useModal();
  
  const handleFormSubmit = ({ status, code }) => {
    setFormStatus({ status, code });
    if (status === 'success') setTimestamp(new Date());
    toggleModal();
  };

  const handleCodeChanges = (code, isValid) => {
    setCode(code);
    setCodeStatus(isValid);
  };

  return (

    <div className="is-inner-page">
      <Layout>

        <SEO title="Cadastro" />

        {!institutionId ? (
          <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <b>ATENCÃO: Você precisa escolher uma instituição primeiro.</b>
          </div>
        ) : (
          <>
            <section
              className="section has-dots"
              style={{ backgroundImage: `url(${require('../images/backgrounds/section-dots-bg.svg')})` }}>
              <div className="container is-medium">
                <header className="section-header visible-desktop-true">
                  <h1 className="title is-xxlarge">Cadastro do código</h1>
                  <div className="v-space" />
                  <p className="text">
                    <strong>Procure na etiqueta do produto da campanha</strong>
                    <br/>
                    (válido para peças das coleções Malwee (Alto Verão, Celebration, Malwee Kids e Malwee Liberta))
                    <br />
                    <span className="is-highlighted">o código com 14 digitos que começa com <strong>"10000"</strong> (1 seguido de 4 zeros) da <strong>#atitudedoBem.</strong></span>
                  </p>
                </header>
                <div className="columns">
                  <Barcode onChange={handleCodeChanges} timestamp={timestamp} />
                  <Form code={code} codeIsValid={codeStatus} institutionId={institutionId} onChangeStatus={handleFormSubmit} />
                </div>
              </div>
            </section>
            <Modal>
              <Feedbacks
                state={formStatus}
                onClickToClose={toggleModal}
              />
            </Modal>
          </>
        )}

      </Layout>
    </div>
  );
};

export default Submit;
